import { Typography, Paper, } from "@mui/material";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { StorageKeys } from "../../constants/StorageKeys";
import { storage } from "../../utils/Storage";
import Page from "../../components/Page";
import { Routes } from "../../constants/Routes";
import Section from "../../components/Section";
import Loading from "../../components/Loading";

const SuccessfulLoginLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    storage.set(StorageKeys.IS_USER_AUTHENTICATED, true);
  
    const targetRouteBeforLogin = storage.get(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN);
    storage.remove(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN);
  
    if (targetRouteBeforLogin) {
      navigate(targetRouteBeforLogin);
    } else {
      navigate(Routes.PROFILE);
    }
  }, []);

  return (
    <Page>
      <Section title="Sikeres belépés">
        <Loading />
      </Section>
    </Page>
  );
};

export default SuccessfulLoginLayout;
