import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Stack,
  Typography,
  Button,
  Grid,
  Card,
  IconButton,
  Drawer,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import useGetAppointments from '../api/useGetAppointments';
import SelectedSupply from '../components/SelectedSupply';
import Page from '../components/Page';
import ErrorSnackbar from '../components/CustomSnackbar';
import Loading from '../components/Loading';
import usePagination from '../hooks/usePagination';
import CustomButton from '../components/CustomButton';
import { useNavigate } from "react-router-dom";
import { Routes } from "../constants/Routes";
import Section from '../components/Section';
 
const BookingLayout = () => {
  const { getAppointments, loading, error, data: availableAppointments } = useGetAppointments();
  const [lastDate, setLastDate] = useState(new Date());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate = useNavigate();

  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);

  const fetchAppointments = () => { 
    const newDate = new Date(lastDate);
    newDate.setDate(newDate.getDate() + 12);
    getAppointments({ date: newDate });
    setLastDate(newDate);
  };

  const {
    currentPage,
    paginatedItems,
    handlePrevPage,
    handleNextPage
  } = usePagination(availableAppointments, fetchAppointments);

  
  useEffect(() => {
    const appointments = getAppointments();
  }, []);

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    } 
  }, [error]);


  const handleAppointmentClick = (date, time) => {
    storage.set(StorageKeys.SELECTED_APPOINTMENT, { date, time });
    setDrawerOpen(true);
  };

  const handleCancelAppointment = () => {
    storage.remove(StorageKeys.SELECTED_APPOINTMENT);
    setDrawerOpen(false);
  }

  const renderDays = () => {
    return paginatedItems.map(appointmentDay => {
      const date = appointmentDay.date;
      return (
        <Grid key={date} item xs={4} sm={3} md={3} lg={2}>
          <Card>
            <Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-around" alignItems="center" p={1} bgcolor="black">
              <Typography variant="h6" textTransform="capitalize" color="white" noWrap>
                {appointmentDay.weekday}
              </Typography>
              <Typography variant="body1" color="grey.200" noWrap fontSize={{ xs: 12, sm: 14, md: 16 }}>
                {appointmentDay.formattedDate}
              </Typography>
            </Stack>
            <Box sx={{ height: "50vh", overflowY: "auto" }}>
              <Stack p={1} spacing={1}>
                {appointmentDay.times.map(time => (
                  <Button
                    key={`${date}-${time}`}
                    variant={
                      selectedAppointment && selectedAppointment.date === date && selectedAppointment.time === time ?
                        "contained" : "outlined"
                    }
                    color="primary"
                    onClick={() => handleAppointmentClick(date, time)}
                  >
                    {time}
                  </Button>
                ))}
              </Stack>
            </Box>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <>
      <ErrorSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}/>

      <Page>
        <Section xsHorizontalPadding={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={{ xs: 3, md: 5}}>
            <Typography variant="h5" fontWeight="bold" color="black">A választott szolgáltatás</Typography>
            <SelectedSupply supply={selectedSupply}/>
          </Stack>
        </Section>

        {loading && <Loading />}

        {!loading && !error && (
          <>
            <Box my={1} display="flex" justifyContent="center" alignItems="center">
              <IconButton onClick={handlePrevPage} disabled={currentPage === 1}>
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton onClick={handleNextPage}>
                <ArrowForwardIosIcon />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              {renderDays()}
            </Grid>
          </>
        )}
      </Page>

      <Drawer anchor="bottom" open={drawerOpen} onClose={handleCancelAppointment}>
        <Stack direction="row" spacing={2} justifyContent="space-evenly" alignItems="center" p={2}> 
          <Stack direction="row" spacing={{ xs: 1, sm: 3}} alignItems="center"> 
            <SelectedSupply supply={selectedSupply} />

            {selectedAppointment && 
              <Stack>
                <Typography fontWeight="bold" fontSize={{ xs: 14, sm: 16 }} noWrap>{selectedAppointment.date.replaceAll("-", ".")}</Typography>
                <Typography color="text.primary" fontSize={{ xs: 14, sm: 16 }}>{selectedAppointment.time}</Typography>
              </Stack>
            }
          </Stack>
         
          <CustomButton text="Tovább" onClick={() => navigate(Routes.PRE_RESERVE)}/>
        </Stack>
      </Drawer>
    </>
  );
};

export default BookingLayout;
