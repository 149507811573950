import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import { useNavigate } from "react-router-dom";
import { Routes } from '../constants/Routes';

const CANCEL_BOOKING_ENDPOINT = '/v1/booking/cancel';

const useCancelBooking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const cancelBooking = async (requestData) => {
    setLoading(true);
    setError(null);

    try {
      const url = CANCEL_BOOKING_ENDPOINT + "/" + requestData;
      await apiClient.post(url);
      setSuccess(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        //TODO make it common
        storage.set(StorageKeys.IS_USER_AUTHENTICATED, false)
        console.log("Request is unauthenticated, redirect needed to login route");
        storage.set(StorageKeys.TARGET_ROUTE_BEFORE_LOGIN, Routes.PROFILE);
        navigate(Routes.LOGIN);
      } else {
        handleError(error, setError);
      }   
    } finally {
      setLoading(false);
    }
  };

  return { cancelBooking, loading, error, success };
};

export default useCancelBooking;
