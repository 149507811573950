import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";
import Page from '../components/Page';
import Section from "../components/Section";
import Loading from '../components/Loading';

const LogoutLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    storage.set(StorageKeys.IS_USER_AUTHENTICATED, false);
    navigate("/")
  }, []);

  return (
    <Page>
      <Section title="Kijelentkezés">
        <Loading />
      </Section>
    </Page>
  );
}

export default LogoutLayout
