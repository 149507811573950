import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const CustomButton = ({ 
  variant = 'contained',
  color = 'primary',
  onClick,
  text,
  textTransform = 'capitalize',
  type
}) => {
  return (
    <Box display="flex" justifyContent="center">
      <Button variant={variant} color={color} onClick={onClick} type>
        <Typography fontWeight="bold" textTransform={textTransform}>
          {text}
        </Typography>
      </Button>
    </Box>
  );
};

export default CustomButton;
