import React from 'react'
import { CircularProgress } from '@mui/material'

const Loading = ({ size = 72 }) => {
  return (
    <CircularProgress size={size} sx={{ my: 5 }} />
  )
}

export default Loading
