import { 
  Typography,
  Button, 
} from "@mui/material";
import Page from "../components/Page";
import API_BASE_URL from '../config';
import isEmbeddedBrowser from "../utils/embeddedBrowserChecker";
import React, { useState, useEffect } from 'react';
import Section from "../components/Section";

const LoginLayout = () => {
  const [embeddedBrowser, setEmbeddedBrowser] = useState(false);

  useEffect(() => {
    setEmbeddedBrowser(isEmbeddedBrowser());
  }, []);

  const handleSocialLogin = (provider) => {  
    window.location = API_BASE_URL + "/v1/auth/initiateSocialLogin?provider=" + provider; 
  };

  return (
    <Page>
      <Section xsHorizontalPadding={2} title="Bejelentkezés" titleColor="primary">
        <Typography variant="body1" align="center" color="text.primary" mb={5} noWrap fontSize={{ xs: "14px", sm: "16px" }}>
          A foglalás véglegesítéséhez be kell jelentkezned!
        </Typography>

        {!embeddedBrowser &&
          <Button
            variant="outlined"
            color="primary"
            startIcon={<img src="/google-icon.svg" alt="Google Icon" style={{ width: '18px', height: '18px' }} />} 
            onClick={() => handleSocialLogin("google")}
          >
            <Typography textTransform="none" color="secondary" noWrap>Bejelentkezés Google-lal</Typography>
          </Button> 
        }

        {embeddedBrowser && (
          <>
            <Typography variant="h6" color="error" mt={2} textAlign="left" fontWeight="bold">
              Használd az alkalmazást egy másik böngészőből!
            </Typography>
            <Typography variant="body2" color="error" mt={2} textAlign="left" fontWeight="bold">
              Ha messenger, instagram vagy egyéb másik alkalmazásból nyitod meg a weboldalt akkor a bejelentkezés nem lehetséges. Másold át a linket egy böngészőbe!
            </Typography>
          </>
        )}
      </Section>
    </Page>  
  );
};

export default LoginLayout;
