import { Divider, Stack, Box, Typography, IconButton, useTheme } from "@mui/material";
import SelectedSupply from "./SelectedSupply";
import { alpha } from "@mui/material/styles";
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from "./Loading";

const BookingItem = ({ booking, callback, loading }) => {
  const theme = useTheme();

  return (
    <Stack 
      key={booking?.id} 
      direction="row" 
      alignItems="center" 
      spacing={{ xs: 1, sm: 4, md: 2 }} 
      divider={<Divider orientation="vertical" flexItem/>}>
      <Box>
        <Typography fontSize={14} align="center" fontWeight="bold" gutterBottom>{booking?.date.replaceAll("-", ".")}</Typography>
        <Typography variant="body2" align="center" noWrap>{`${booking?.fromTime} - ${booking?.toTime}`}</Typography> 
      </Box>

      <SelectedSupply supply={booking?.supply} />

      {loading ? 
        <Loading size={40} /> 
        : 
        <IconButton
          color="error"
          onClick={() => callback(booking.id)}
          sx={{
            color: theme.palette.error.main,
            '&:hover': {
              backgroundColor: alpha(theme.palette.error.light, 0.3),
            },
          }}
        >
          <DeleteIcon />
        </IconButton>
      }
    </Stack>
  )
}

export default BookingItem
