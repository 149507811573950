import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';
import { StorageKeys } from "../constants/StorageKeys";
import { storage } from "../utils/Storage";

const GET_APPOINTMENTS_ENDPOINT = '/v1/booking/getAppointments';

const useGetAppointments = () => {
  const supply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  
  const getAppointments = async (requestData) => {
    setLoading(true);
    setError(null);

    try {
      const url = composeUrl(requestData, supply);
      const response = await apiClient.get(url);
      setData((oldData) => [...oldData, ...response.data]);
    } catch (error) {
      handleError(error, setError);
    } finally {
      setLoading(false);
    }
  };

  return { getAppointments, loading, error, data };
};

const composeUrl = (requestData, supply) => {
  let url = GET_APPOINTMENTS_ENDPOINT + "/" + supply?.id;

  if (requestData?.date) {
    const date = new Date(requestData.date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    url += "?date=" + formattedDate;
  }

  return url;
}

export default useGetAppointments;
