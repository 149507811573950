import { Typography } from "@mui/material";
import Page from "../../components/Page";
import Section from "../../components/Section";

const ErrorLayout = () => {
  return (
    <Page>
      <Section title="Hiba történt!" titleColor="red" minWidth={{ xs: "70vw", md: "60vw", lg: "40vw", xl: "20vw" }}>
        <Typography variant="body2" color="red">Próbáld újra később</Typography>
      </Section>
    </Page>
  )
}

export default ErrorLayout;
