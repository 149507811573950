import { useState, useEffect } from 'react';
import {
  Stack,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import useGetUser from "../api/useGetUser";
import useCancelBooking from '../api/useCancelBooking';
import Page from '../components/Page';
import CustomSnackbar from '../components/CustomSnackbar';
import Loading from '../components/Loading';
import Section from '../components/Section';
import BorderedBox from '../components/BorderedBox';
import BookingItem from '../components/BookingItem';
import { StorageKeys } from '../constants/StorageKeys';
import { storage } from "../utils/Storage";

const ProfileLayout = () => {
  const { getUser, loading: getUserLoading, error: getUserError, data: getUserData } = useGetUser();
  const { cancelBooking, loading: cancelBookingLoading, error: cancelBookingError, success: cancelBookingSuccess } = useCancelBooking();

  const [isDialogOpen, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState();
  const [reFetchData, setReFetchData] = useState(false);

  const bookingToBeCancelled = storage.get(StorageKeys.BOOKING_TO_BE_CANCELLED);

  useEffect(() => {
    getUser();
  }, [reFetchData]);

  useEffect(() => {
    if (getUserError) {
      showSnackbar(getUserError?.message);
      return;
    }

    if (cancelBookingError) {
      showSnackbar(cancelBookingError?.message);
      return;
    }

    if (cancelBookingSuccess) {
      showSnackbar("Sikeresen lemondtad a foglalást", "success")
    }

  }, [getUserError, cancelBookingError, cancelBookingSuccess]);
  
  const showSnackbar = async (message, severity = "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenDialog = (bookingId) => {
    storage.set(StorageKeys.BOOKING_TO_BE_CANCELLED, bookingId);
    setOpen(true);
  };

  const rejectBookingCancelation = () => {
    storage.remove(StorageKeys.BOOKING_TO_BE_CANCELLED);
    setOpen(false);
  };

  const handleConfirmCancel = () => {
    cancelBooking(bookingToBeCancelled)
    setOpen(false);
    setReFetchData(prev => !prev);
  };

  return (
    <>
      <CustomSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}/>

      <Dialog
        open={isDialogOpen}
      >
        <DialogTitle>Foglalás törlése</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Biztosan törölni szeretnéd ezt a foglalást?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={rejectBookingCancelation} color="secondary">
            Mégse
          </Button>
          <Button variant="contained" onClick={handleConfirmCancel} color="error" fontWeight="bold">
            <Typography textTransform="none">Törlés</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      
      <Page>
        {getUserLoading && <Loading />}

        {!getUserLoading && getUserData && (
          <Stack 
            direction={{ xs: "column",  md: "row" }} 
            spacing={{ xs: 3, sm: 4, md: 5 }} alignItems={{ xs: "center", md: "stretch" }}>
            {getUserData.bookings.length > 0 && 
              <Section 
                title="Foglalások" 
                xsHorizontalPadding={3} 
                width={{ xs: "80vw", sm: "60vw", md: "40vw", lg: "30vw", xl: "25vw" }}>

                <Stack divider={<Divider orientation="horizontal" flexItem/>} spacing={3} alignItems="center">
                  {getUserData.bookings.map((booking) => (
                    <BookingItem booking={booking} callback={handleOpenDialog} loading={cancelBookingLoading && bookingToBeCancelled === booking?.id }/>
                  ))}
                </Stack>
              </Section>
            }
            
            <Section
              title="Profil" 
              xsHorizontalPadding={3}
              width={{ xs: "80vw", sm: "60vw", md: "30vw", lg: "20vw", xl: "20vw" }}>

              <Stack spacing={3}>
                <BorderedBox title="Név" lable={getUserData.name} />
                <BorderedBox title="Email" lable={getUserData.email} />
                <BorderedBox title="Telefonszám" lable={getUserData.phoneNumber} />
              </Stack>
            </ Section>
          </Stack>
        )}
      </Page>
    </>
  );
  
};

export default ProfileLayout;
