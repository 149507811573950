import { useState } from 'react';
import apiClient, { handleError } from './ApiClient';

const COMPLETE_REGISTRATION_ENDPOINT = '/v1/auth/complete-registration';

const useCompleteRegistration = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const completeRegistration = async (requestData) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.post(COMPLETE_REGISTRATION_ENDPOINT, requestData);
      const redirectUrl = response?.data?.redirectUrl;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        setError({ message: "Sajnos nem sikerült a véglegesíteni regisztrációt. Foglalj időpontot telefonon keresztül. 06 30 452 8948" });
      }
    } catch (error) {
      handleError(error, setError)
    } finally {
      setLoading(false);
    }
  };

  return { completeRegistration, loading, error };
};

export default useCompleteRegistration;
